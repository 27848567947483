.loginbody {
  /* background-image: linear-gradient(160deg, #fab2ff 20%, #1904e5 100%); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: "Open Sans", sans-serif;
  width: 100vw;

}

.title {
  font-size: 50px;
  text-align: center;

  padding: 80px 0;
  font-family: "Raleway", sans-serif;
  color: rgb(245, 245, 245);
  font-weight: 600;
}

.box-form {
  width: 85%;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 40px 1px #090b6f85;
}
@media (max-width: 980px) {
  .box-form {
    flex-flow: wrap;
    text-align: center;
    align-content: center;
    align-items: center;
  }
}
.box-form div {
  height: auto;
}
.box-form .left {
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 50%;
}

@media (max-width: 980px) {
  .box-form .left {
    width: 100%;
  }
}
.box-form .left .overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.box-form .left .overlay h1 {
  font-size: 4rem;
  line-height: 1;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px;
}
.box-form .left .overlay span p {
  margin-top: 30px;
  font-weight: 900;
}
.box-form .left .overlay span a {
  background: #3b5998;
  color: #ffffff;
  margin-top: 10px;
  padding: 14px 50px;
  border-radius: 100px;
  display: inline-block;
  box-shadow: 0 3px 6px 1px #042d4657;
}
.box-form .left .overlay span a:last-child {
  background: #1dcaff;
  margin-left: 30px;
}
.box-form .right {
  overflow: hidden;
}
@media (max-width: 980px) {
  .box-form .right {
    width: 100%;
  }
}
.box-form .right h5 {
  font-size: 6vmax;
  line-height: 0;
}
.box-form .right p {
  font-size: 14px;
  color: #b0b3b9;
}
.box-form .right .inputs {
  overflow: hidden;
}
.box-form .right input {
  width: 100%;
  margin-top: 25px;
  font-size: 18px;
  padding: 10px;
  border: none;
  outline: none;
  border-bottom: 2px solid #b0b3b9;
}
.box-form .right .remember-me--forget-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-form .right .remember-me--forget-password input {
  margin: 0;
  margin-right: 7px;
  width: auto;
}

label {
  display: block;
  position: relative;
  margin-left: 30px;
}

label::before {
  content: " \f00c";
  position: absolute;
  font-family: FontAwesome;
  background: transparent;
  border: 3px solid #70f570;
  border-radius: 4px;
  color: transparent;
  left: -30px;
  transition: all 0.2s linear;
}

label:hover::before {
  font-family: FontAwesome;
  content: " \f00c";
  color: #fff;
  cursor: pointer;
  background: #70f570;
}

label:hover::before .text-checkbox {
  background: #70f570;
}

label span.text-checkbox {
  display: inline-block;
  height: auto;
  position: relative;
  cursor: pointer;
  transition: all 0.2s linear;
}

label input[type="checkbox"] {
  display: none;
}

.button {
  width: 75%;
  color: #E5E7EB;
  pointer-events: auto;
  cursor: pointer;
  background: #e7e7e7;
  border: none;
  padding: 1rem 3rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
}

.button::before,
.button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button--pandora {
  background: #3c6aa5;
  font-weight: 700;
  padding: 0;
  border-radius: 5px;
}

.button--pandora span {
  display: block;
  background: #25284E;
  padding: 1rem 2rem 1rem 2rem;
  border: 1px solid #000;
  border-radius: 5px;
  transform: translate3d(0px, -2px, 0);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  -webkit-transform: translate3d(0px, -2px, 0);
  -moz-transform: translate3d(0px, -2px, 0);
  -ms-transform: translate3d(0px, -2px, 0);
  -o-transform: translate3d(0px, -2px, 0);
}

.button--pandora:hover span {
  transform: translate3d(0px, -6px, 0);
  -webkit-transform: translate3d(0px, -6px, 0);
  -moz-transform: translate3d(0px, -6px, 0);
  -ms-transform: translate3d(0px, -6px, 0);
  -o-transform: translate3d(0px, -6px, 0);
}
